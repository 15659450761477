.contact__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
}

.section__subtitle-contact {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: 2rem;
    text-align: center;
  }