.skills__container {
  grid-template-columns: repeat(2, 350px);
  column-gap: 3rem;
  justify-content: center;
}

.skills__content {
  padding: 2rem 2rem;
  border-radius: 1.25rem;
}

.skills__title {
  font-size: 1.5rem;
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 3rem;
}

.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 1rem;
}

.skills__data {
  display: flex;
  column-gap: 0.5rem;
}

.skills .bx {
  font-size: 2rem;
  color: var(--title-color-dark);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills__name-no-label {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 32px;
  }

.skills__level {
  font-size: 0.75rem;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

/* For medium devices */
@media screen and (max-width: 576px) {
  .skills__container {
    grid-template-columns: 1fr;
  }

  .skills__content {
    padding: 1.5rem;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
    skills__box{
        column-gap: 1.25rem;
    }

    .skills__name{
        font-size: var(--small-font-size);
    }
}
