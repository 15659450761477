.footer{
    background-color: var(--container-color);
    border-top: 1px solid rgba(0,0,0,0.1);
}

.footer__container{
    padding: 1rem 0 1rem;
}

.footer__link{
    color: var(--title-color);
}
.footer__link:hover{
    color: var(--title-color-dark);
}

.footer__list{
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.footer__social{
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}

.footer__social-link{
    background-color: var(--title-color);
    stop-color: var(--container-color);
    font-size: 1.125rem;
    padding: 0.4rem;
    border-radius: 0.5rem;
    display: inline-flex;
}

.footer__sicial-link:hover{
    background-color: var(--title-color-dark);
}



