.header {
  width: 100%;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
}

.nav__logo{
  display: none;
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__list {
  display: flex;
  column-gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: 0.3s;
  justify-content: end;
}

/* Active link */

.active-link,
.nav__link:hover {
  color: var(--title-color-dark);
}

.show-header{
  box-shadow: 0 -1px 4px rgba(0,0,0,0.15);
}
